<template>
  <div class="d-flex align-items-center dayrow">
    <div
      v-for="(day, dayIndex) in weekDays"
      :key="`key-${dayIndex}`"
      class="daycol p-2 border-start border-bottom"
    >{{ day }}</div>
  </div>
</template>

<script>
export default {
  name: 'CalendarWeekDays',
  data() {
    return {
      weekDays: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
    };
  },
};
</script>

<style lang="scss" scoped>
.dayrow {
  background-color: white;
}

.daycol {
  &:first-child {
    border-left: 0 !important;
  }
}
</style>
